<template>
  <div>
    <div class="agent-line-container">
      <span v-text="$t('agent_line')" /> :
      <div
        v-if="agentLines.length > 0"
        class="agent-line-list-container"
      >
        <span
          v-for="(item, index) in agentLines"
          :key="item.id"
        >
          <span
            class="agent-line-list"
            @click="loadAccounts(item.id)"
            v-text="item.username"
          />
          <span
            v-if="agentLines.length - 1 !== index"
            v-text="`/`"
          />
        </span>
      </div>
    </div>
    <agent-overview
      :agent="agent"
    />
    <!-- Table Top -->
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="5"
          lg="3"
        >
          <b-input-group class="mb-1-sm">
            <b-form-input
              v-model="filter.account"
              :placeholder="$t('account')"
              autocomplete="off"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                @click="loadData(1)"
                v-text="$t('search')"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col
          v-if="userData.is_sub_account === 0 || (userData.is_sub_account === 1 && userData.authority === 'Edit')"
          cols="12"
          md="7"
          lg="9"
        >
          <div
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              v-for="(actType, index) in accountType"
              :key="`actType_${index}`"
              variant="primary"
              class="ml-1"
              @click="createAccountModal(actType.value)"
            >
              {{ $t(`create_${actType.value}`) }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.80"
    >
      <b-card
        v-if="agent.role === 'admin' || agent.role === 'company' || agent.role == 'distributor'"
        :title="agentTitle"
        class="mb-5"
      >
        <!-- table -->
        <vue-good-table
          :columns="accounts.columns"
          :rows="accounts.records"
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
        >
          <!-- Rows -->
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Account Status -->
            <div
              v-if="props.column.field === 'account_status'"
              style="position: relative;"
            >
              <span
                v-if="props.row.account_status === true"
                style="position: absolute;right: 65px;"
                v-text="$t('enable')"
              />
              <span
                v-else
                style="position: absolute;right: 65px;"
                v-text="$t('disable')"
              />
              <b-form-checkbox
                v-if="userData.is_sub_account === 0 || (userData.is_sub_account === 1 && userData.authority === 'Edit')"
                v-model="props.row.account_status"
                class="custom-control-dark"
                name="check-button"
                :disabled="!userData.can_edit"
                switch
                @change="changeStatus($event, props.row)"
              />
            </div>
            <!-- Column: Account Type -->
            <span
              v-else-if="props.column.field === 'account_type'"
              v-text="$t(props.row.account_type)"
            />
            <!-- Column: Account -->
            <span
              v-else-if="props.column.field === 'account'"
            >
              <a
                v-if="props.row.account_type !== 'member'"
                href="#"
                class="align-middle ml-50"
                @click="loadAccounts(props.row.id)"
              >
                <feather-icon
                  :icon="`UserIcon`"
                  style="margin-right:2px;"
                />
                <span v-text="props.row.account" />
              </a>
              <span
                v-else
                v-text="props.row.account"
              />
            </span>
            <!-- Column: Balance -->
            <span
              v-else-if="props.column.field === 'balance'"
              v-text="Number(props.row.balance).toLocaleString()"
            />
            <!-- Column: Total Balance -->
            <span
              v-else-if="props.column.field === 'total_balance'"
              v-text="Number(props.row.total_balance).toLocaleString()"
            />
            <span
              v-else-if="props.column.field === 'operation'"
            >
              <b-button
                variant="outline-primary"
                size="sm"
                @click="showOperation(props.row)"
                v-text="$t('operation')"
              />
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- Columns -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span v-text="$t(props.column.label)" />
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-0">
                <span class="text-nowrap">
                  <span v-text="$t('pagination_showing')" /> 1 <span v-text="$t('pagination_to')" />
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['50','100','200', '300']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> <span v-text="$t('pagination_of')" /> {{ props.total }}<span v-text="$t('pagination_entries')" /> </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-2"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
      <b-card
        v-if="agent.role !== 'admin' && agent.role !== 'company' && agent.role !== 'distributor'"
        :title="$t('members')"
        class="mb-5"
      >
        <!-- table -->
        <vue-good-table
          :columns="members.columns"
          :rows="members.records"
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
        >
          <!-- Rows -->
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Account -->
            <span
              v-if="props.column.field === 'account'"
            >
              <a
                v-if="props.row.account_type !== 'member'"
                href="#"
                class="align-middle ml-50"
                @click="loadAccounts(props.row.id)"
              >
                <feather-icon
                  :icon="`UserIcon`"
                  style="margin-right:2px;"
                />
                <span v-text="props.row.account" />
              </a>
              <span
                v-else
                v-text="props.row.account"
              />
            </span>
            <!-- Column: Shares -->
            <span
              v-else-if="props.column.field === 'shares'"
              v-text="`${props.row.shares}%`"
            />
            <!-- Column: Commission -->
            <span
              v-else-if="props.column.field === 'commission'"
              v-text="`${props.row.commission}%`"
            />
            <!-- Column: Balance -->
            <span
              v-else-if="props.column.field === 'balance'"
              v-text="Number(props.row.balance).toLocaleString()"
            />
            <span
              v-else-if="props.column.field === 'operation'"
            >
              <b-button
                variant="outline-primary"
                size="sm"
                @click="showOperation(props.row)"
                v-text="$t('operation')"
              />
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- Columns -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span v-text="$t(props.column.label)" />
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-0">
                <span class="text-nowrap">
                  <span v-text="$t('pagination_showing')" /> 1 <span v-text="$t('pagination_to')" />
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['50','100','200', '300']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> <span v-text="$t('pagination_of')" /> {{ props.total }}<span v-text="$t('pagination_entries')" /> </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-2"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>

    <!-- Create Modal -->
    <b-modal
      ref="create-account-modal"
      size="lg"
      :title="$t('create_child_agent')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="account-modal"
        @submit.stop.prevent="createAccountHandleSubmit"
      >
        <b-row>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="agent.username"
              :statistic-title="$t('agent')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="agent.currency"
              :statistic-title="$t('agent_line')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="`${agent.share}%`"
              :statistic-title="$t('shares')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              icon="CpuIcon"
              :statistic="`${agent.commission}%`"
              :statistic-title="$t('commission')"
            />
          </b-col>
        </b-row>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('account_type')"
          style="text-align: right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
              style="position:relative; text-align:left;"
            >
              <b-form-radio-group
                v-model="formData.account_type"
                :options="accountType"
                name="radio-inline"
                style="padding-top: 8px;"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            />
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="formData.account_type === 'agent' || (formData.account_type === 'member' && agent.can_multi_bet)"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('can_multi_bet')"
          style="text-align: right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
              style="position:relative; text-align:left; margin-top: 6px"
            >
              <b-form-checkbox
                v-model="formData.can_multi_bet"
                name="can-multi-bet"
                value="true"
                unchecked-value="false"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            />
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="(formData.account_type === 'distributor' && agent.role === 'company') || (formData.account_type === 'distributor' && (agent.role === 'distributor' && agent.can_create_distributor === 'Yes'))"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('can_create_distributor')"
          style="text-align: right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
              style="position:relative; text-align:left;"
            >
              <b-form-radio-group
                v-model="formData.can_create_distributor"
                :options="canCreateDistributorOptions"
                name="can-create-agent"
                style="padding-top: 8px;"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            />
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('country')"
          style="margin-bottom: 0px; text-align: right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-select
                v-if="formData.account_type === 'company'"
                v-model="formData.country_code"
                :options="countryOptions"
                class="mb-1"
              />
              <p
                v-else
                style="padding: 8px 0px; text-align: left"
                v-text="formData.country_code"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('currency')"
          style="margin-bottom: 0px; text-align: right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-select
                v-if="formData.account_type === 'company'"
                v-model="formData.currency"
                :options="currencyOptions"
                class="mb-1"
              />
              <p
                v-else
                style="padding: 8px 0px; text-align: left"
                v-text="formData.currency"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('shares')"
          label-for="create-account-input-shares"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="create-account-input-shares"
                v-model="formData.share"
                type="number"
                autocomplete="off"
                @focus="numberFocus('share')"
                @blur="numberBlur('share')"
                @keyup="validateMaxShare()"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span
                class="create-child-requirements"
                style="line-height: 38px;"
              >
                <span v-text="$t('limit')" /> {{ agent.share }}%
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('commission')"
          label-for="create-account-input-commission"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="create-account-input-commission"
                v-model="formData.commission"
                type="number"
                autocomplete="off"
                @focus="numberFocus('commission')"
                @blur="numberBlur('commission')"
                @keyup="validateMaxCommission()"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span
                class="create-child-requirements"
                style="line-height: 38px;"
              >
                <span v-text="$t('limit')" /> {{ agent.commission }}%
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <hr>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('account')"
          label-for="create-account-input-account"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="create-account-input-account"
                v-model="formData.account"
                autocomplete="off"
                maxlength="20"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span
                class="create-child-requirements"
                style="line-height: 38px;"
              >
                <span v-text="$t('create_modify_user_account_field')" />
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('password')"
          label-for="create-account-input-password"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="create-account-input-password"
                v-model="formData.password"
                type="password"
                autocomplete="off"
                maxlength="20"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span class="create-child-requirements">
                <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                <span v-text="$t('create_modify_user_password_field_requirements_2')" />
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('password_confirm')"
          label-for="create-account-input-password-confirm"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="create-account-input-password-confirm"
                v-model="formData.password_confirmation"
                type="password"
                autocomplete="off"
                maxlength="20"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span class="create-child-requirements">
                <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                <span v-text="$t('create_modify_user_password_field_requirements_2')" />
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('name')"
          label-for="create-account-input-nickname"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="create-account-input-nickname"
                v-model="formData.nickname"
                :maxlength="maxNickNameCount"
                autocomplete="off"
                @keyup="getNickNameRemainingCount()"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span style="line-height: 38px;"><span v-text="$t('remaining_words')" /> {{ nickNameRemaining }}</span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="formData.account_type === 'agent'"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('person_in_charge')"
          label-for="create-account-input-person-in-charge"
          class="hidden"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="create-account-input-person-in-charge"
                v-model="formData.person_in_charge"
                autocomplete="off"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span
                class="create-child-requirements"
                style="line-height: 38px;"
              >
                <span v-text="$t('required')" />
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('telegram_id')"
          label-for="create-account-input-telegram-id"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="create-account-input-telegram-id"
                v-model="formData.telegram_id"
                autocomplete="off"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            />
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="formData.account_type === 'agent'"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('telegram_id')"
          label-for="create-account-input-email-address"
          class="hidden"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="create-account-input-email-address"
                v-model="formData.email_address"
                autocomplete="off"
                type="email"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span
                class="create-child-requirements"
                style="line-height: 38px;"
              >
                <span v-text="$t('required')" />
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="formData.account_type === 'agent'"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('white_list_ips')"
          label-for="create-account-input-white-list-ips"
          class="hidden"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="create-account-input-white-list-ips"
                v-model="formData.white_list_ips"
                autocomplete="off"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span
                class="create-child-requirements"
                style="line-height: 38px;"
              >
                <span v-text="$t('required')" />
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="formData.account_type === 'agent'"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('logout_url')"
          label-for="create-account-input-logout-url"
          class="hidden"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="create-account-input-logout-url"
                v-model="formData.logout_url"
                autocomplete="off"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span
                class="create-child-requirements"
                style="line-height: 38px;"
              >
                <span v-text="$t('required')" />
              </span>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="createAccountHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="createAccountHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal
      ref="edit-account-modal"
      cancel-variant="outline-secondary"
      ok-title="Enter"
      size="lg"
      :title="$t('modify_account')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="account-modal"
        @submit.stop.prevent="editAccountHandleSubmit"
      >
        <b-row
          v-if="formData.account_type === 'agent'"
        >
          <b-col
            cols="12"
            md="12"
            xl="12"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="formData.api_key"
              :statistic-title="$t('api_key')"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="agent.username"
              :statistic-title="$t('agent')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="agent.currency"
              :statistic-title="$t('agent_line')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="`${agent.share}%`"
              :statistic-title="$t('shares')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              icon="CpuIcon"
              :statistic="`${agent.commission}%`"
              :statistic-title="$t('commission')"
            />
          </b-col>
        </b-row>
        <b-row
          v-if="formData.account_type === 'partner'"
        >
          <b-col
            cols="12"
            md="12"
            xl="12"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="formData.api_key"
              :statistic-title="$t('api_key')"
            />
          </b-col>
        </b-row>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('account_type')"
          style="text-align: right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
              style="position:relative; text-align:left;"
            >
              <b-form-radio-group
                v-model="formData.account_type"
                :options="accountType"
                name="radio-inline"
                style="padding-top: 8px;"
                disabled
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            />
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="formData.account_type === 'agent' || (formData.account_type === 'member' && agent.can_multi_bet)"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('can_multi_bet')"
          style="text-align: right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
              style="position:relative; text-align:left; margin-top: 6px"
            >
              <b-form-checkbox
                v-model="formData.can_multi_bet"
                name="can-multi-bet"
                value="true"
                unchecked-value="false"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            />
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="(formData.account_type === 'distributor' && agent.role === 'company') || (formData.account_type === 'distributor' && (agent.role === 'distributor' && agent.can_create_distributor === 'Yes'))"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('can_create_distributor')"
          style="text-align: right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
              style="position:relative; text-align:left;"
            >
              <b-form-radio-group
                v-model="formData.can_create_distributor"
                :options="canCreateDistributorOptions"
                name="can-create-distributor"
                style="padding-top: 8px;"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            />
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('country')"
          style="margin-bottom: 0px; text-align: right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <p
                style="padding: 8px 0px; text-align:left;"
                v-text="formData.country_code"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('currency')"
          style="margin-bottom: 0px; text-align: right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
              style="position:relative; text-align:left;"
            >
              <p
                style="padding: 8px 0px; text-align:left;"
                v-text="formData.currency"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          label-for="edit-account-input-shares"
          :label="$t('shares')"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="edit-account-input-shares"
                v-model="formData.share"
                type="number"
                autocomplete="off"
                @focus="numberFocus('share')"
                @blur="numberBlur('share')"
                @keyup="validateMaxShare()"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span
                class="create-child-requirements"
                style="line-height: 38px;"
              >
                <span v-text="$t('limit')" /> {{ agent.share }}%
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('commission')"
          label-for="edit-account-input-commission"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="edit-account-input-commission"
                v-model="formData.commission"
                type="number"
                autocomplete="off"
                @focus="numberFocus('commission')"
                @blur="numberBlur('commission')"
                @keyup="validateMaxCommission()"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span
                class="create-child-requirements"
                style="line-height: 38px;"
              >
                <span v-text="$t('limit')" /> {{ agent.commission }}%
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('account')"
          label-for="edit-account-input-account"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="edit-account-input-account"
                v-model="formData.account"
                autocomplete="off"
                maxlength="20"
                disabled
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span
                class="create-child-requirements"
                style="line-height: 38px;"
              >
                <span v-text="$t('create_modify_user_account_field')" />
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('password')"
          label-for="edit-account-input-password"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="edit-account-input-password"
                v-model="formData.password"
                type="password"
                autocomplete="off"
                maxlength="20"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span class="create-child-requirements">
                <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                <span v-text="$t('create_modify_user_password_field_requirements_2')" />
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('password_confirm')"
          label-for="edit-account-input-password-confirm"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="edit-account-input-password-confirm"
                v-model="formData.password_confirmation"
                type="password"
                autocomplete="off"
                maxlength="20"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span class="create-child-requirements">
                <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                <span v-text="$t('create_modify_user_password_field_requirements_2')" />
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('name')"
          label-for="edit-account-input-nickname"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="edit-account-input-nickname"
                v-model="formData.nickname"
                :maxlength="maxNickNameCount"
                autocomplete="off"
                @keyup="getNickNameRemainingCount()"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span style="line-height: 38px;"><span v-text="$t('remaining_words')" /> {{ nickNameRemaining }}</span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="formData.account_type === 'agent'"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('person_in_charge')"
          label-for="edit-account-input-person-in-charge"
          class="hidden"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="edit-account-input-person-in-charge"
                v-model="formData.person_in_charge"
                autocomplete="off"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span
                class="create-child-requirements"
                style="line-height: 38px;"
              >
                <span v-text="$t('required')" />
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('telegram_id')"
          label-for="edit-account-input-telegram-id"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
              style="position:relative"
            >
              <b-form-input
                id="edit-account-input-telegram-id"
                v-model="formData.telegram_id"
                autocomplete="off"
              />
            </b-col>
            <b-col
              v-if="formData.account_type === 'partner'"
              cols="12"
              md="12"
              xl="5"
            />
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="formData.account_type === 'agent'"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('telegram_id')"
          label-for="edit-account-input-email-address"
          class="hidden"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="edit-account-input-email-address"
                v-model="formData.email_address"
                autocomplete="off"
                type="email"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span
                class="create-child-requirements"
                style="line-height: 38px;"
              >
                <span v-text="$t('required')" />
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="formData.account_type === 'agent'"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('white_list_ips')"
          label-for="edit-account-input-white-list-ips"
          class="hidden"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="edit-account-input-white-list-ips"
                v-model="formData.white_list_ips"
                autocomplete="off"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span
                class="create-child-requirements"
                style="line-height: 38px;"
              >
                <span v-text="$t('required')" />
              </span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="formData.account_type === 'agent'"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('logout_url')"
          label-for="edit-account-input-logout-url"
          class="hidden"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="edit-account-input-logout-url"
                v-model="formData.logout_url"
                autocomplete="off"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            >
              <span
                class="create-child-requirements"
                style="line-height: 38px;"
              >
                <span v-text="$t('valid_url')" />
              </span>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center;"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="editAccountHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="editAccountHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Edit Partner Limit Modal -->
    <b-modal
      ref="edit-partner-limit-modal"
      cancel-variant="outline-secondary"
      ok-title="Enter"
      size="lg"
      :title="$t('modify_partner_limits')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="account-modal"
        @submit.stop.prevent="editPartnerLimitHandleSubmit"
      >
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <span v-text="$t('min')" />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <span v-text="$t('max')" />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('player_slash_banker')"
          label-for="edit-account-input-partner-limit-player-banker-min"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-partner-limit-player-banker-min"
                v-model="partnerLimitFormData.player_banker_min"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('min')"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-partner-limit-player-banker-max"
                v-model="partnerLimitFormData.player_banker_max"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('max')"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('tie')"
          label-for="edit-account-input-partner-limit-tie-min"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-partner-limit-tie-min"
                v-model="partnerLimitFormData.tie_min"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('min')"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-partner-limit-tie-max"
                v-model="partnerLimitFormData.tie_max"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('max')"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('p_pair_and_b_pair')"
          label-for="edit-account-input-partner-limit-b-pair-and-p-pair-min"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-partner-limit-b-pair-and-p-pair-min"
                v-model="partnerLimitFormData.p_pair_and_b_pair_min"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('min')"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-partner-limit-b-pair-and-p-pair-max"
                v-model="partnerLimitFormData.p_pair_and_b_pair_max"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('max')"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center;"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="editPartnerLimitHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="editPartnerLimitHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Edit Member Limit Modal -->
    <b-modal
      ref="edit-member-limit-modal"
      cancel-variant="outline-secondary"
      ok-title="Enter"
      size="lg"
      :title="$t('modify_member_limits')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="account-modal"
        @submit.stop.prevent="editMemberLimitHandleSubmit"
      >
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <span v-text="$t('min')" />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <span v-text="$t('max')" />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('player_slash_banker')"
          label-for="edit-account-input-member-limit-player-banker-min"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-member-limit-player-banker-min"
                v-model="memberLimitFormData.player_banker_min"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('min')"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-member-limit-player-banker-max"
                v-model="memberLimitFormData.player_banker_max"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('max')"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('tie')"
          label-for="edit-account-input-member-limit-tie-min"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-member-limit-tie-min"
                v-model="memberLimitFormData.tie_min"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('min')"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-member-limit-tie-max"
                v-model="memberLimitFormData.tie_max"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('max')"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('p_pair_and_b_pair')"
          label-for="edit-account-input-member-limit-b-pair-and-p-pair-min"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-member-limit-b-pair-and-p-pair-min"
                v-model="memberLimitFormData.p_pair_and_b_pair_min"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('min')"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-member-limit-b-pair-and-p-pair-max"
                v-model="memberLimitFormData.p_pair_and_b_pair_max"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('max')"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center;"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="editMemberLimitHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="editMemberLimitHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Operation Modal -->
    <b-modal
      ref="operation-modal"
      :title="$t('agent_operation')"
      ok-title="Close"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-row
        v-if="selectedChildAgent.account_type !== 'member'"
      >
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <statistic-card-vertical
            show-icon="false"
            :statistic="agent.username"
            :statistic-title="$t('agent')"
          />
        </b-col>
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <statistic-card-vertical
            show-icon="false"
            :statistic="selectedChildAgent.account"
            :statistic-title="$t('child_agent')"
          />
        </b-col>
      </b-row>
      <b-row
        class="mb-1"
      >
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="editAccount(selectedChildAgent.id)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('modify_account')"
            />
          </b-button>
        </b-col>
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="showLogs()"
          >
            <feather-icon
              icon="MonitorIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('log')"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row
        class="mb-1"
      >
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="showTransfer(selectedChildAgent, 'transfer_to_child', $t('transfer_to_child'))"
          >
            <feather-icon
              icon="UsersIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('transfer_to_child')"
            />
          </b-button>
        </b-col>
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="showTransfer(selectedChildAgent, 'transfer_from_child', $t('transfer_from_child'))"
          >
            <feather-icon
              icon="UsersIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('transfer_from_child')"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-if="selectedChildAgent.account_type === 'partner' || selectedChildAgent.account_type === 'member'"
        class="mb-1"
      >
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-if="selectedChildAgent.account_type === 'partner'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="editPartnerLimit(selectedChildAgent.id)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('modify_partner_limits')"
            />
          </b-button>
          <b-button
            v-if="selectedChildAgent.account_type === 'member'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="editMemberLimit(selectedChildAgent)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('modify_member_limits')"
            />
          </b-button>
        </b-col>
      </b-row>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="operationHandleCancel"
              v-text="$t('close')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Transfer Modal -->
    <b-modal
      ref="transfer-modal"
      size="lg"
      :title="transferFormTitle"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="transfer-modal"
        @submit.stop.prevent="transferHandleSubmit"
      >
        <b-card>
          <b-row style="border: 2px solid rgb(0 0 0);padding: 7px;border-radius: 5px;color: black;margin: 0px 0px;">
            <b-col
              cols="6"
              md="6"
              xl="6"
              class="text-left font-weight-bold"
            >
              <span
                style="font-weight: bold; font-size: 19px;"
                v-text="$t('agent')"
              />
              <span
                style="font-size: 19px;"
                v-text="` - ${agent.username}`"
              />
            </b-col>
            <b-col
              cols="6"
              md="6"
              xl="6"
              class="text-left"
            >
              <span
                style="font-weight: bold; font-size: 19px;"
                v-text="$t('balance')"
              />
              <span
                style="font-size: 19px;"
                v-text="` - ${Number(agent.balance).toLocaleString()}`"
              />
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row style="border: 2px solid rgb(0 0 0);padding: 7px;border-radius: 5px;color: black;margin: 0px 0px;">
            <b-col
              cols="6"
              md="6"
              xl="6"
              class="text-left font-weight-bold"
            >
              <span
                style="font-weight: bold; font-size: 19px;"
                v-text="$t('child_agent')"
              />
              <span
                style="font-size: 19px;"
                v-text="` - ${selectedChildAgent.account}`"
              />
            </b-col>
            <b-col
              cols="6"
              md="6"
              xl="6"
              class="text-left"
            >
              <span
                style="font-weight: bold; font-size: 19px;"
                v-text="$t('balance')"
              />
              <span
                style="font-size: 19px;"
                v-text="` - ${Number(selectedChildAgent.balance).toLocaleString()}`"
              />
            </b-col>
          </b-row>
        </b-card>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('transfer_amount')"
          label-for="transfer-input-amount"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="transfer-input-amount"
                v-model="transferFormData.amount"
                type="text"
                autocomplete="off"
                @keyup.stop.native="changeAmount()"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            />
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('memo')"
          label-for="transfer-input-memo"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-textarea
                id="transfer-input-memo"
                v-model="transferFormData.memo"
                rows="3"
                :maxlength="maxMemoCount"
                @keyup="getMemoRemainingCount()"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
              style="text-align: left;"
            >
              <span style="line-height: 38px;"><span v-text="$t('remaining_words')" /> {{ memoRemaining }}</span>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="transferHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="transferHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Logs -->
    <b-modal
      ref="logs-modal"
      size="xl"
      :title="`${selectedChildAgent.account} ${$t('log')}`"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-row class="p-1">
        <!-- Filter -->
        <b-card
          footer-tag="footer"
          class="mb-1 card-filter-container"
          style="width: 100%"
        >
          <b-row>
            <b-col
              sm="12"
              md="6"
              lg="6"
              class="card-filter-item"
            >
              <b-input-group class="mb-1">
                <b-input-group-prepend is-text>
                  <span
                    style="min-width: 130px;"
                    v-text="$t('date_from')"
                  />
                </b-input-group-prepend>
                <b-form-datepicker
                  v-model="logFilter.dateFrom"
                  :max="logFilter.dateTo"
                />
              </b-input-group>
              <b-input-group class="mb-1-sm">
                <b-input-group-prepend is-text>
                  <span
                    style="min-width: 130px;"
                    v-text="$t('time_from')"
                  />
                </b-input-group-prepend>
                <b-form-timepicker
                  v-model="logFilter.timeFrom"
                  show-seconds
                  locale="en"
                />
              </b-input-group>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="6"
              class="card-filter-item"
            >
              <b-input-group class="mb-1">
                <b-input-group-prepend is-text>
                  <span
                    style="min-width: 130px;"
                    v-text="$t('date_to')"
                  />
                </b-input-group-prepend>
                <b-form-datepicker
                  v-model="logFilter.dateTo"
                  :min="logFilter.dateFrom"
                />
              </b-input-group>
              <b-input-group class="mb-1-sm">
                <b-input-group-prepend is-text>
                  <span
                    style="min-width: 130px;"
                    v-text="$t('time_to')"
                  />
                </b-input-group-prepend>
                <b-form-timepicker
                  v-model="logFilter.timeTo"
                  show-seconds
                  locale="en"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
              lg="12"
              class="card-filter-item mt-1"
            >
              <b-button
                variant="primary float-right"
                @click="loadLogData"
                v-text="$t('search')"
              />
            </b-col>
          </b-row>
        </b-card>
        <b-overlay
          :show="logLoading"
          opacity="0.80"
          rounded="sm"
          style="width: 100%"
        >
          <b-card>
            <!-- table -->
            <vue-good-table
              :columns="logColumns"
              :rows="logs"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <!-- Rows -->
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- Columns -->
              <template
                slot="table-column"
                slot-scope="props"
              >
                <span v-text="$t(props.column.label)" />
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-0">
                    <span class="text-nowrap">
                      <span v-text="$t('pagination_showing')" /> 1 <span v-text="$t('pagination_to')" />
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['50','100','200', '300']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap "> <span v-text="$t('pagination_of')" /> {{ props.total }}<span v-text="$t('pagination_entries')" /> </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-2"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
          <br>
        </b-overlay>
      </b-row>
    </b-modal>

    <!-- View Transaction List Modal -->
    <b-modal
      ref="view-transaction-list-modal"
      size="xl"
      :title="`${selectedChildAgent.account} ${$t('transactions')}`"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-row class="p-1">
        <b-col
          cols="12"
          md="12"
          xl="12"
        >
          <b-overlay
            :show="transactionList.loading"
            opacity="0.80"
            rounded="sm"
          >
            <div class="card">
              <div class="card-body">
                <div class="vgt-wrap">
                  <div class="vgt-inner-wrap">
                    <div class="vgt-responsive">
                      <table class="vgt-table bordered">
                        <thead>
                          <tr>
                            <th class="vgt-left-align">
                              <span v-text="$t('partner_name')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('account')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('time')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('shoe_id')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('round_id')" />
                            </th>
                            <th class="vgt-left-align sortable">
                              <span v-text="$t('transaction_type')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('before')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('amount')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('after')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('memo')" />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in transactionList.records"
                            :key="item.id"
                          >
                            <!-- Partner Name -->
                            <td class="vgt-left-align">
                              <span v-text="item.partner_name" />
                            </td>
                            <!-- Account -->
                            <td class="vgt-left-align">
                              <span v-text="item.account" />
                            </td>
                            <!-- Time -->
                            <td class="vgt-left-align">
                              <span v-text="item.time" />
                            </td>
                            <!-- Shoe ID -->
                            <td class="vgt-left-align">
                              <span class="game-table-id">{{ item.game_table_id }}</span>
                              <span class="game-shoe-id">{{ item.game_shoe_id }}</span>
                              <span class="game-round-id">{{ item.game_round_id }}</span>
                            </td>
                            <!-- Round ID -->
                            <td class="vgt-left-align">
                              <span v-text="item.round_id" />
                            </td>
                            <!-- Transaction Type -->
                            <td class="vgt-left-align">
                              <span v-text="item.transaction_type" />
                            </td>
                            <!-- Before -->
                            <td class="vgt-left-align">
                              <span
                                class="align-middle ml-50"
                                v-text="item.before"
                              />
                            </td>
                            <!-- Amount -->
                            <td class="vgt-left-align">
                              <span
                                class="align-middle ml-50"
                                v-text="item.amount"
                              />
                            </td>
                            <!-- After -->
                            <td class="vgt-left-align">
                              <span
                                class="align-middle ml-50"
                                v-text="item.after"
                              />
                            </td>
                            <!-- Memo -->
                            <td class="vgt-left-align">
                              <span v-text="item.memo" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <b-pagination
                  v-model="transactionList.filter.page"
                  :per-page="transactionList.perPage"
                  hide-goto-end-buttons
                  :total-rows="transactionList.totalPage"
                  align="center"
                  @change="loadTransactionList"
                />
              </div>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import StatisticCardVertical from '@/components/statistics-cards/StatisticCardVertical.vue'
import AgentOverview from '@/components/agent-overview/AgentOverview.vue'
import Ripple from 'vue-ripple-directive'

import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'

import {
  BRow,
  BCol,
  BButton,
  BOverlay,
  BCard,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormCheckbox,
  BFormInput,
  BFormSelect,
  BForm,
  BFormGroup,
  BFormRadioGroup,
  BFormTextarea,
  BFormDatepicker,
  BFormTimepicker,
} from 'bootstrap-vue'

import Account from '@/models/Account'
import Game from '@/models/Game'
import Log from '@/models/Log'
import Transaction from '@/models/Transaction'

import { EventBus } from '../../../eventbus'

export default {
  name: 'Accounts',
  components: {
    StatisticCardVertical,
    AgentOverview,
    VueGoodTable,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BCard,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormCheckbox,
    BFormInput,
    BFormSelect,
    BForm,
    BFormGroup,
    BFormRadioGroup,
    BFormTextarea,
    BFormDatepicker,
    BFormTimepicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      loading: true,
      countryOptions: [
        { value: 'JAP', text: 'JAPAN' },
        { value: 'KOR', text: 'KOREA' },
      ],
      currencyOptions: [
        { value: 'JPY', text: 'JAP' },
        { value: 'KRW', text: 'KOR' },
      ],
      filter: {
        account: typeof this.$route.query.account !== 'undefined' ? this.$route.query.account : '',
        agent: typeof this.$route.query.agent !== 'undefined' ? this.$route.query.agent : '',
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
      },
      accounts: {
        records: [],
        columns: [
          {
            label: 'account',
            field: 'account',
            type: 'text',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
            width: '170px',
          },
          {
            label: 'name',
            field: 'nickname',
            type: 'text',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'dl_count',
            field: 'dl_count',
            type: 'number',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'telegram_id',
            field: 'telegram_id',
            type: 'number',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'account_status',
            field: 'account_status',
            type: 'boolean',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'account_type',
            field: 'account_type',
            type: 'text',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'own_balance',
            field: 'balance',
            type: 'number',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'create_time',
            field: 'create_time',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'last_login',
            field: 'last_login',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
        ],
      },
      members: {
        records: [],
        columns: [
          {
            label: 'account',
            field: 'account',
            type: 'text',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
            width: '170px',
          },
          {
            label: 'name',
            field: 'nickname',
            type: 'text',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'telegram_id',
            field: 'telegram_id',
            type: 'number',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'game_limit',
            field: 'game_limit',
            type: 'text',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'shares',
            field: 'shares',
            type: 'number',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'commission',
            field: 'commission',
            type: 'number',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'balance',
            field: 'balance',
            type: 'number',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'create_time',
            field: 'create_time',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
          {
            label: 'last_login',
            field: 'last_login',
            filterOptions: {
              enabled: false,
              placeholder: 'Search',
            },
          },
        ],
      },
      pageLength: 50,
      nickNameRemaining: 20,
      maxNickNameCount: 20,
      maxMemoCount: 180,
      memoRemaining: 180,
      agent: {
        id: '',
        username: '',
        can_multi_bet: false,
      },
      agentLines: [],
      accountType: [],
      memberColumns: [
        {
          label: 'idx',
          field: 'idx',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
          width: '170px',
        },
        {
          label: 'company',
          field: 'company',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
          width: '170px',
        },
        {
          label: 'agent',
          field: 'agent',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
          width: '170px',
        },
        {
          label: 'partner_id',
          field: 'partner_id',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
          width: '170px',
        },
        {
          label: 'partner_name',
          field: 'partner_name',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
          width: '170px',
        },
        {
          label: 'user_id',
          field: 'user_id',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
          width: '170px',
        },
        {
          label: 'operation',
          field: 'operation',
          sortable: false,
          thClass: 'vgt-center-align',
          tdClass: 'vgt-center-align',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'balance',
          field: 'balance',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
          width: '170px',
        },
        {
          label: 'created_at',
          field: 'created_at',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
          width: '170px',
        },
        {
          label: 'last_login',
          field: 'last_login',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
          width: '170px',
        },
      ],
      agentTitle: '',
      formData: {
        loading: false,
        id: '',
        account: '',
        password: '',
        password_confirmation: '',
        nickname: '',
        person_in_charge: '',
        email_address: '',
        white_list_ips: '',
        logout_url: '',
        account_type: '',
        parent_id: 0,
        telegram_id: '',
        partner_id: '',
        member_id: '',
        share: 0,
        commission: 0,
        game_limit: null,
        game_limits: [],
        currency: null,
        country_code: null,
        can_create_distributor: 'No',
        can_multi_bet: true,
      },
      memberLimitFormData: {
        loading: false,
        userId: '',
        memberId: '',
        partnerId: '',
        player_banker_min: 0,
        player_banker_max: 0,
        tie_min: 0,
        tie_max: 0,
        p_pair_and_b_pair_min: 0,
        p_pair_and_b_pair_max: 0,
      },
      partnerLimitFormData: {
        loading: false,
        partnerId: '',
        player_banker_min: 0,
        player_banker_max: 0,
        tie_min: 0,
        tie_max: 0,
        p_pair_and_b_pair_min: 0,
        p_pair_and_b_pair_max: 0,
      },
      selectedChildAgent: {
        id: null,
        account: '',
      },
      transferFormTitle: '',
      transferFormData: {
        loading: false,
        transfer_from: '',
        transfer_to: '',
        amount: '',
        max_amount: 0,
        memo: '',
        transaction_type: '',
        role_from: '',
        role_to: '',
      },
      logFilter: {
        dateFrom: `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 518400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 518400000).getDate())}`,
        dateTo: `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
        timeFrom: '00:00:00',
        timeTo: '23:59:59',
        operatorAccount: '',
      },
      logLoading: false,
      logColumns: [
        {
          label: 'operator_account',
          field: 'operator_account',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'type',
          field: 'type',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'related_account',
          field: 'related_account',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'time',
          field: 'time',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'ip',
          field: 'ip',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'memo',
          field: 'memo',
          sortable: false,
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
      ],
      logs: [],
      transactionList: {
        loading: false,
        perPage: 50,
        totalPage: 0,
        filter: {
          page: 0,
          memberId: '',
          type: 'deposit,withdrawal',
        },
        records: [],
      },
      canCreateDistributorOptions: [{
        text: 'Yes',
        value: 'Yes',
      }, {
        text: 'No',
        value: 'No',
      }],
    }
  },
  mounted() {
    this.loadData()
    if (this.userData.is_sub_account === 0 || (this.userData.is_sub_account === 1 && this.userData.authority === 'Edit')) {
      this.accounts.columns.push({
        label: 'operation',
        field: 'operation',
        sortable: false,
        thClass: 'vgt-center-align',
        tdClass: 'vgt-center-align',
        filterOptions: {
          enabled: false,
          placeholder: 'Search',
        },
      })
      this.members.columns.push({
        label: 'operation',
        field: 'operation',
        sortable: false,
        thClass: 'vgt-center-align',
        tdClass: 'vgt-center-align',
        filterOptions: {
          enabled: false,
          placeholder: 'Search',
        },
      })
    }
  },
  methods: {
    loadAccounts(id) {
      this.filter.agent = id
      this.filter.account = ''
      this.loadData(1)
    },
    async loadData() {
      this.loading = true
      const searchParams = {}
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })
      this.$router.replace({ query: searchParams })
      const response = await Account.list(searchParams)
      this.accounts.records = response.data.accounts
      this.members.records = response.data.members
      this.agent = response.data.meta.agent
      this.agentLines = response.data.meta.agent_lines
      this.accountType = response.data.meta.options.account_types

      if (this.agent.role === 'admin') {
        this.agentTitle = this.$t('companies')
      } else if (this.agent.role === 'company') {
        this.agentTitle = this.$t('distributors')
      } else if (this.agent.role === 'distributor') {
        this.agentTitle = `${this.$t('agents')} / ${this.$t('distributors')}`
      } else if (this.agent.role === 'agent') {
        this.agentTitle = this.$t('agents')
      }

      EventBus.$emit('getMyUpdatedUserData')
      this.loading = false
    },
    createAccountModal(type) {
      this.resetFormData()
      this.formData.account_type = type
      this.$refs['create-account-modal'].show()
    },
    createAccountHandleCancel() {
      this.$refs['create-account-modal'].hide()
    },
    getNickNameRemainingCount() {
      if (this.formData.nickname) {
        this.nickNameRemaining = this.maxNickNameCount - this.formData.nickname.length
      } else {
        this.nickNameRemaining = this.maxNickNameCount
      }
    },
    async createAccountHandleSubmit() {
      try {
        if (this.formData.loading === false) {
          this.formData.loading = true
          this.formData.agent = this.agent
          const response = await Account.store(this.formData)
          this.formData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.filter.account = ''
            await this.loadData(1)
            this.$refs['create-account-modal'].hide()
            this.resetFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.field_id !== 'undefined') {
              if (this.formData.account_type !== null) {
                document.getElementById(response.data.results.field_id).focus()
              }
            }
          }
        }
      } catch (e) {
        this.formData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    resetFormData() {
      this.formData = {
        loading: false,
        id: '',
        account: '',
        password: '',
        password_confirmation: '',
        nickname: '',
        person_in_charge: '',
        email_address: '',
        white_list_ips: '',
        logout_url: '',
        account_type: '',
        parent_id: this.agent.id,
        telegram_id: '',
        partner_id: '',
        member_id: '',
        share: 0,
        commission: 0,
        game_limit: null,
        game_limits: [],
        country_code: this.agent.country_code,
        currency: this.agent.currency,
        can_create_distributor: 'No',
        can_multi_bet: true,
      }
      this.getNickNameRemainingCount()
      if (this.accountType.length > 0) {
        this.formData.account_type = this.accountType[0].value
      }
    },
    async showOperation(data) {
      this.selectedChildAgent = data
      this.$refs['operation-modal'].show()
    },
    operationHandleCancel() {
      this.$refs['operation-modal'].hide()
    },
    showTransfer(data, transactionType, title) {
      this.resetTransferFormData()
      this.transferFormTitle = title
      this.transferFormData.transaction_type = transactionType
      if (transactionType === 'transfer_to_child') {
        this.transferFormData.max_amount = this.agent.balance
        this.transferFormData.transfer_from = this.agent.id
        this.transferFormData.transfer_to = data.id
        this.max_amount = this.agent.balance
        this.transferFormData.role_from = this.agent.role
        this.transferFormData.role_to = data.account_type
      } else {
        this.transferFormData.max_amount = data.balance
        this.transferFormData.transfer_from = data.id
        this.transferFormData.transfer_to = this.agent.id
        this.max_amount = data.balance
        this.transferFormData.role_from = data.account_type
        this.transferFormData.role_to = this.agent.role
      }
      this.selectedChildAgent = data
      this.$refs['transfer-modal'].show()
    },
    transferHandleCancel() {
      this.$refs['transfer-modal'].hide()
    },
    resetTransferFormData() {
      this.transferFormData = {
        loading: false,
        transfer_from: '',
        transfer_to: '',
        amount: '',
        memo: '',
        transaction_type: '',
      }
    },
    async transferHandleSubmit() {
      try {
        if (this.transferFormData.loading === false) {
          this.transferFormData.loading = true
          const response = await Transaction.transfer(this.transferFormData)
          this.transferFormData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            await this.loadData()
            this.$refs['transfer-modal'].hide()
            this.selectedChildAgent.balance = response.data.results.custom.transaction.balance
            this.resetTransferFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.field_id !== 'undefined') {
              document.getElementById(response.data.results.field_id).focus()
            }
          }
        }
      } catch (e) {
        this.transferFormData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    getMemoRemainingCount() {
      if (this.transferFormData.memo) {
        this.memoRemaining = this.maxMemoCount - this.transferFormData.memo.length
      } else {
        this.memoRemaining = this.maxMemoCount
      }
    },
    changeAmount() {
      let result = 0
      let balance = 0

      if (this.transferFormData.transaction_type === 'transfer_to_child') {
        balance = this.agent.balance
      } else {
        balance = this.selectedChildAgent.balance
      }
      if (Number(String(this.transferFormData.amount).replace(/\D/g, '')) > balance) {
        result = String(balance).replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace('.00', '')
      } else {
        result = String(this.transferFormData.amount).replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      this.transferFormData.amount = result
    },
    showLogs() {
      this.$refs['logs-modal'].show()
      this.logFilter.relatedAccount = this.selectedChildAgent.account
      this.logFilter.dateFrom = `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 518400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 518400000).getDate())}`
      this.logFilter.dateTo = `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`
      this.logFilter.timeFrom = '00:00:00'
      this.logFilter.timeTo = '23:59:59'
      this.loadLogData()
    },
    async loadLogData() {
      this.logLoading = true
      const searchParams = {}
      Object.keys(this.logFilter).forEach(e => {
        if (typeof this.logFilter[e] === 'number') {
          if (this.logFilter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        }
        if (typeof this.logFilter[e] === 'object') {
          if (this.logFilter[e].length > 0) {
            let newData = ''
            Object.keys(this.logFilter[e]).forEach(d => {
              newData += `${this.logFilter[e][d].value},`
            })
            searchParams[e] = newData
          }
        } else if (typeof this.logFilter[e] === 'string') {
          if (this.logFilter[e] !== null && this.logFilter[e].length > 0) {
            searchParams[e] = this.logFilter[e]
          }
        }
      })
      const response = await Log.list(searchParams)
      this.logs = response.data.logs
      this.logLoading = false
    },
    async editAccount(id) {
      this.resetFormData()
      const response = await Account.edit({ id })
      this.formData = response.data
      this.formData.loading = false
      this.getNickNameRemainingCount()
      this.$refs['edit-account-modal'].show()
    },
    editAccountHandleCancel() {
      this.$refs['edit-account-modal'].hide()
    },
    async editAccountHandleSubmit() {
      try {
        if (this.formData.loading === false) {
          this.formData.loading = true
          const response = await Account.update(this.formData)
          this.formData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            await this.loadData()
            this.selectedChildAgent.account = response.data.results.custom.account.username
            this.selectedChildAgent.balance = response.data.results.custom.account.balance
            this.selectedChildAgent.share = response.data.results.custom.account.share
            this.selectedChildAgent.commission = response.data.results.custom.account.commission
            this.$refs['edit-account-modal'].hide()
            this.resetFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.field_id !== 'undefined') {
              document.getElementById(response.data.results.field_id).focus()
            }
          }
        }
      } catch (e) {
        this.formData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    partnerLimitResetFormData() {
      this.partnerLimitFormData.loading = false
      this.partnerLimitFormData.partnerId = ''
      this.partnerLimitFormData.player_banker_min = 0
      this.partnerLimitFormData.player_banker_max = 0
      this.partnerLimitFormData.p_pair_and_b_pair_min = 0
      this.partnerLimitFormData.p_pair_and_b_pair_max = 0
      this.partnerLimitFormData.tie_min = 0
      this.partnerLimitFormData.tie_max = 0
    },
    async editPartnerLimit(id) {
      this.partnerLimitResetFormData()
      const response = await Account.edit({ id })
      this.partnerLimitFormData.loading = false
      this.partnerLimitFormData.partnerId = response.data.api_id
      this.partnerLimitFormData.player_banker_min = response.data.partnerLimit.player_banker_min
      this.partnerLimitFormData.player_banker_max = response.data.partnerLimit.player_banker_max
      this.partnerLimitFormData.p_pair_and_b_pair_min = response.data.partnerLimit.p_pair_and_b_pair_min
      this.partnerLimitFormData.p_pair_and_b_pair_max = response.data.partnerLimit.p_pair_and_b_pair_max
      this.partnerLimitFormData.tie_min = response.data.partnerLimit.tie_min
      this.partnerLimitFormData.tie_max = response.data.partnerLimit.tie_max
      this.getNickNameRemainingCount()
      this.$refs['edit-partner-limit-modal'].show()
    },
    editPartnerLimitHandleCancel() {
      this.$refs['edit-partner-limit-modal'].hide()
    },
    async editPartnerLimitHandleSubmit() {
      try {
        if (this.partnerLimitFormData.loading === false) {
          this.partnerLimitFormData.loading = true
          const response = await Account.updatePartnerLimit(this.partnerLimitFormData)
          this.partnerLimitFormData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.$refs['edit-partner-limit-modal'].hide()
            this.partnerLimitResetFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.field_id !== 'undefined') {
              document.getElementById(response.data.results.field_id).focus()
            }
          }
        }
      } catch (e) {
        this.partnerLimitFormData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    memberLimitResetFormData() {
      this.memberLimitFormData.loading = false
      this.memberLimitFormData.partnerId = ''
      this.memberLimitFormData.userId = ''
      this.memberLimitFormData.memberId = ''
      this.memberLimitFormData.player_banker_min = 0
      this.memberLimitFormData.player_banker_max = 0
      this.memberLimitFormData.p_pair_and_b_pair_min = 0
      this.memberLimitFormData.p_pair_and_b_pair_max = 0
      this.memberLimitFormData.tie_min = 0
      this.memberLimitFormData.tie_max = 0
    },
    async editMemberLimit(data) {
      this.memberLimitFormData.partnerId = data.partnerId
      this.memberLimitFormData.userId = data.id
      this.memberLimitFormData.memberId = data.memberId
      this.memberLimitFormData.player_banker_min = data.memberLimit.player_banker_min
      this.memberLimitFormData.player_banker_max = data.memberLimit.player_banker_max
      this.memberLimitFormData.p_pair_and_b_pair_min = data.memberLimit.p_pair_and_b_pair_min
      this.memberLimitFormData.p_pair_and_b_pair_max = data.memberLimit.p_pair_and_b_pair_max
      this.memberLimitFormData.tie_min = data.memberLimit.tie_min
      this.memberLimitFormData.tie_max = data.memberLimit.tie_max
      this.$refs['edit-member-limit-modal'].show()
    },
    editMemberLimitHandleCancel() {
      this.$refs['edit-member-limit-modal'].hide()
    },
    async editMemberLimitHandleSubmit() {
      try {
        if (this.memberLimitFormData.loading === false) {
          this.memberLimitFormData.loading = true
          const response = await Account.updateMemberLimit(this.memberLimitFormData)
          this.memberLimitFormData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.$refs['edit-member-limit-modal'].hide()
            this.selectedChildAgent.memberLimit.player_banker_min = this.memberLimitFormData.player_banker_min
            this.selectedChildAgent.memberLimit.player_banker_max = this.memberLimitFormData.player_banker_max
            this.selectedChildAgent.memberLimit.p_pair_and_b_pair_min = this.memberLimitFormData.p_pair_and_b_pair_min
            this.selectedChildAgent.memberLimit.p_pair_and_b_pair_max = this.memberLimitFormData.p_pair_and_b_pair_max
            this.selectedChildAgent.memberLimit.tie_min = this.memberLimitFormData.tie_min
            this.selectedChildAgent.memberLimit.tie_max = this.memberLimitFormData.tie_max
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.field_id !== 'undefined') {
              document.getElementById(response.data.results.field_id).focus()
            }
          }
        }
      } catch (e) {
        this.memberLimitFormData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    viewTransactionList(memberId) {
      this.transactionList.filter.memberId = memberId
      this.$refs['view-transaction-list-modal'].show()
      this.loadTransactionList(1)
    },
    async loadTransactionList(page) {
      this.transactionList.loading = true
      const searchParams = {}
      this.transactionList.filter.page = page
      Object.keys(this.transactionList.filter).forEach(e => {
        if (typeof this.transactionList.filter[e] === 'number') {
          if (this.transactionList.filter[e] > 0) {
            searchParams[e] = this.transactionList.filter[e]
          }
        } else if (typeof this.transactionList.filter[e] === 'string') {
          if (this.transactionList.filter[e] !== null && this.transactionList.filter[e].length > 0) {
            searchParams[e] = this.transactionList.filter[e]
          }
        }
      })
      const response = await Game.transactions(searchParams)
      this.transactionList.records = response.data.data
      this.transactionList.totalPage = response.data.meta.pagination.total
      this.transactionList.perPage = response.data.meta.pagination.per_page
      this.transactionList.loading = false
    },
    validateMaxCommission() {
      if (this.formData.commission > this.agent.commission) {
        this.formData.commission = this.agent.commission
      }
      if (this.formData.commission < 0 || this.formData.commission.length === 0) {
        this.formData.commission = ''
      }
    },
    numberFocus(data) {
      if (data === 'share') {
        if (this.formData.share === '0' || this.formData.share === 0) {
          this.formData.share = ''
        }
      }
      if (data === 'commission') {
        if (this.formData.commission === '0' || this.formData.commission === 0) {
          this.formData.commission = ''
        }
      }
    },
    numberBlur(data) {
      if (data === 'share') {
        if (this.formData.share < 0 || this.formData.share.length === 0) {
          this.formData.share = 0
        }
      }
      if (data === 'commission') {
        if (this.formData.commission < 0 || this.formData.commission.length === 0) {
          this.formData.commission = 0
        }
      }
    },
    validateMaxShare() {
      if (this.formData.share > this.agent.share) {
        this.formData.share = this.agent.share
      }
      if (this.formData.share < 0 || this.formData.share.length === 0) {
        this.formData.share = ''
      }
    },
    async changeStatus(status, data) {
      await Account.changeStatus({ id: data.id, status })
    },
  },
}
</script>

<style>

</style>
