import axios from '@/libs/axios'

class Account {
  static getDashboard() {
    return axios.post('user/dashboard')
  }

  static list(params) {
    return axios.post('user/accounts', params)
  }

  static getPartnerInfo() {
    return axios.post('user/account/partner-info')
  }

  static store(params) {
    return axios.post('user/account/register', params)
  }

  static changeMyPassword(params) {
    return axios.post('user/account/change-my-password', params)
  }

  static me() {
    return axios.post('user/me')
  }

  static edit(params) {
    return axios.post('user/account', params)
  }

  static update(params) {
    return axios.post('user/account/update', params)
  }

  static updatePartnerLimit(params) {
    return axios.post('user/account/update-partner-limit', params)
  }

  static updateMemberLimit(params) {
    return axios.post('user/account/update-member-limit', params)
  }

  static balanceList(params) {
    return axios.post('user/account/balance', params)
  }

  static changeStatus(params) {
    return axios.post('user/account/change-status', params)
  }
}

export default Account
